/* You can add global styles to this file, and also import other style files */
/* Resets */
*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* 1 */
  border-width: 0;
}

:root {
  scroll-behavior: smooth;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
}

*:hover::-webkit-scrollbar-thumb {
  border: 3px solid rgb(198, 198, 198);
}

html,
body {
  height: 100%;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}
