@import './general';
@import 'node_modules/@fortawesome/fontawesome-free/css/all.min.css';

main {
  min-height: 80vh;
}

/** snackbar */
.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ffffff;
  --mdc-snackbar-supporting-text-color: #ffffff;

  &.snackbar-error {
    --mdc-snackbar-container-color: #c91818;
  }

  &.snackbar-success {
    --mdc-snackbar-container-color: #0aa32b;
  }

  &.snackbar-info {
    --mdc-snackbar-container-color: orange;
  }

  & .mat-mdc-button .mdc-button__label {
    color: floralwhite;
  }
}

/** end of snackbar */

ngx-material-timepicker-container {
  .timepicker-overlay[_ngcontent-ng-c2055447140] {
    z-index: 9999;
  }
}

/** end of snackbar */

.headerMenu.mat-mdc-menu-panel {
  min-width: 295px;
  overflow: visible;
  background-color: #ffffff00;

  .mat-mdc-menu-content {
    padding: 0;
  }
}
.userMenu.mat-mdc-menu-panel {
  overflow: visible;
  background-color: #ffffff00;

  .mat-mdc-menu-content {
    padding: 0;
  }
}

.botMenu.mat-mdc-menu-panel {
  overflow: visible;
  background-color: #ffffff00;

  .mat-mdc-menu-content {
    padding: 0;
  }
}

.mat-mdc-form-field {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading {
    @apply border-[#d1d5db] dark:border-gray-600;
    border-radius: 0.7rem 0 0 0.7rem;
  }

  .mdc-text-field--outlined.mdc-text-field--disabled
    .mdc-notched-outline__leading {
    @apply border-[#d1d5db] dark:border-gray-600;
    border-radius: 0.7rem 0 0 0.7rem;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch {
    @apply border-[#d1d5db] dark:border-gray-600;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    @apply border-[#d1d5db] dark:border-gray-600;
    border-radius: 0 0.7rem 0.7rem 0;
  }

  .mdc-text-field--outlined.mdc-text-field--disabled
    .mdc-notched-outline__trailing {
    @apply border-[#d1d5db] dark:border-gray-600;
    border-radius: 0 0.7rem 0.7rem 0;
  }
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}

table {
  .mat-mdc-header-cell {
    @apply text-xl font-semibold text-gray-600 dark:text-white font-sans;
  }

  .actionHeader {
    @apply pl-9;
  }
}

.router-link-active {
  @apply text-white bg-sky-700 shadow-lg;
}
