// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import 'node_modules/@angular/material/prebuilt-themes/azure-blue.css';
@use '@angular/material' as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$azure-blue-theme: mat.define-theme((color: (theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$blue-palette,
      ),
      typography: (brand-family: 'Poppins',
        plain-family: 'Poppins',
        regular-weight: 400,
      ),

    ));

// Define the theme object.
$ers-app-form-field-theme: mat.define-theme((density: (scale: -3,
      ),
    ));


// Include theme styles for core and each component used in your app.
@mixin themable-styles($theme) {
  .tonal {
    background-color: mat.get-theme-color($theme, secondary-container);
    color: mat.get-theme-color($theme, on-secondary-container);
  }

  .filled-table {
    --mat-table-row-item-outline-color: mat.get-theme-color($theme,
        on-secondary-container);
    background-color: mat.get-theme-color($theme, secondary-container);
    color: mat.get-theme-color($theme, on-secondary-container);
    border-radius: 12px;
    overflow: hidden;
  }

  table .mat-mdc-header-cell {
    font-family: 'Poppins';
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: mat.get-theme-color($theme, secondary-container) transparent;
  }

  // Button color variants
  .secondary-button {
    @include mat.button-color($theme, $color-variant: secondary);
  }

  .tertiary-button {
    @include mat.button-color($theme, $color-variant: tertiary);
  }

  .warn-button {
    @include mat.button-color($theme, $color-variant: error);
  }

  // Icon color variants
  .primary-icon {
    @include mat.icon-color($theme, $color-variant: primary);
  }

  .secondary-icon {
    @include mat.icon-color($theme, $color-variant: secondary);
  }

  .tertiary-icon {
    @include mat.icon-color($theme, $color-variant: tertiary);
  }

  .warn-icon {
    @include mat.icon-color($theme, $color-variant: error);
  }
}

// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($azure-blue-theme);
  @include themable-styles($azure-blue-theme);
  @include mat.form-field-density($ers-app-form-field-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($azure-blue-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($theme);

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
@tailwind variants;

@import 'apps/ers/src/assets/scss/ers-style.scss';

div.readonly-section,
td.readonly-section,
a.readonly-section,
li.readonly-section,
button.readonly-section {
  opacity: 0.6;
  cursor: default !important;
  pointer-events: none !important;
}

.cdk-drop-list-dragging {
  opacity: 0.5;
  border-left: 5px solid #000;
  padding-left: 8px;
}
